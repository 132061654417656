import PropTypes from 'prop-types';

import { cmsImagePropTypes } from './cms-image';

const cmsVideoPropTypes = PropTypes.shape({
  autoPlay: PropTypes.bool,
  isIframeVideo: PropTypes.bool,
  poster: cmsImagePropTypes,
  title: PropTypes.string,
  source: PropTypes.string.isRequired,
});

const cmsVideosPropTypes = PropTypes.arrayOf(cmsVideoPropTypes);

export { cmsVideoPropTypes, cmsVideosPropTypes };
