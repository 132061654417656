import { useMediaQuery } from 'react-responsive';

import { breakpoints } from '../../constants';

const useIsMinWidth = breakpoint => {
  const IsMinWidth = useMediaQuery({ query: `(min-width: ${breakpoints[breakpoint]})` });
  return IsMinWidth;
};

export { useIsMinWidth };
